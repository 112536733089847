.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    margin: 0 auto;
}

.section {
    background-color: var(--background-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem;
    overflow: hidden;
    position: relative;
}

.sectionInner {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
    gap: 1rem;
}

#main {
    background: transparent;
    position: relative;
    padding-bottom: 4rem;
}

.angleShape {
    position: absolute;
    top: -90px;
    right: 0;
}

.fieldGlasses {
    position: absolute;
    width: clamp(250px, 25vw, 350px);
    height: clamp(250px, 25vw, 350px);
    object-fit: contain;
    top: 1.5%;
    right: 3vw;
}

.banner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4rem 0;
    padding-top: 6rem;
    gap: 2.5rem;
    position: relative;
}

.title {
    font-size: 4rem;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    flex-direction: row;
    width: 70%;
    gap: 1rem;
    color: var(--text-color);
}

.subtitle {
    font-size: 1.5rem;
    max-width: 1000px;
    text-align: left;
    width: 70%;
}


.actionButton {
    padding: 1.25rem 3rem;
    font-size: 1.125rem;
}

.socialProof {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-top: 6rem;
}

.socialProofBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 1rem;
    padding: 2rem 1rem;
    background-color: rgba(255, 255, 255, 0.5);
    box-shadow: 0px 10px 50px 10px rgba(0, 0, 0, 0.1);

}

.playerWrapper {
    width: 100%;
    aspect-ratio: 16/9;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 16px;
    position: relative;
}

.player {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    overflow: hidden;
}

.videoTopRight {
    position: absolute;
    top: 0;
    right: 0;
}

.videoBottomLeft {
    position: absolute;
    bottom: 0;
    left: 0;
}

.sectionTitle {
    font-size: 2.25rem;
    text-align: center;
}

.parallaxWrapper {
    position: relative;
    overflow: hidden;
}

.problems {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 0;
    width: 100%;
}


.problem {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    padding: 6rem 1rem;
    gap: 2rem;
}

.problemContent {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    padding-left: 4rem;
    justify-content: center;
}

.problemTitle {
    font-weight: 700;
    font-size: 2rem;
}

.solutionTitle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: 700;
    font-size: 1.5rem;
}

.problemNumber {
    position: absolute;
    left: -20%;
    top: 50%;
    line-height: 12rem;
    font-size: 12rem;
    font-weight: 700;
    font-family: var(--primary-font);
    color: rgba(var(--text-color-rgb), 8%);
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
}

.problemLabel {
    position: relative;
    padding-left: 3rem;
    font-size: 3.5rem;
    line-height: 2rem;
}

.problemDescription {
    font-weight: 300;
    line-height: 150%;
    z-index: 1;
}

.solutionContent {
    position: relative;
    display: flex;
    width: 50%;
    justify-content: flex-end;
}

.radialGradient {
    position: absolute;
    width: 500px;
    height: 500px;
    right: -40%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
}

.solutionImage {
    display: flex;
    max-height: 150px;
    max-width: 164px;
}

.solutionImage>svg {
    display: flex;
    width: 100%;
    height: 100%;
}

.solutionDescription {
    font-weight: 300;
    line-height: 150%;
}

.blob {
    position: absolute;
    top: -30%;
    right: 0;
    width: 500px;
    height: 500px;
    object-fit: contain;
    z-index: 0;
}

.blob1 {
    right: -25%;
}

.blob2 {
    left: 5%;
}

.blob3 {
    right: -15%;
}

.masters {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    align-items: center;
}

.unicornBlob {
    display: flex;
    width: 50%;
    max-height: 500px;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.unicornBlob.inline {
    display: none;
    shape-outside: circle();
    clip-path: circle(80%);
    width: 300px;
    height: 300px;
    max-height: 300px;
    float: right;
    margin-left: 1rem;
}

.mastersContent {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 1rem;
}

.peopleBlob {
    display: flex;
    width: 50%;
    max-height: 500px;
}

.peopleBlob.inline {
    display: none;
    shape-outside: circle();
    clip-path: circle(50%);
    width: 300px;
    height: 300px;
    max-height: 300px;
    float: left;
    margin-right: 1rem;
    -webkit-mask-size: cover;
    mask-size: cover;
}

.who {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.whoContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 50%;
}

.footer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: 100%;
    color: #FCFCFC;
    padding: 2rem 0;
}

.footerWho {
    display: flex;
    width: 40%;
    flex-direction: column;
    gap: 2rem;
    padding: 0 1rem;
}

.logo {
    display: flex;
    max-width: 8rem;
    height: 60px;
}

.footerLinks,
.footerContact {
    padding: 0 1rem;
    width: 20%;
}

.footerSocial {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    flex: 1;
}

.links {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    opacity: 0.7;
    font-weight: 700;
    padding: 1rem 0;
}

.links>a {
    color: #FCFCFC !important;
    font-size: 0.875rem;
}

.socials {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-start;
}

.social {
    display: flex;
    height: 48px;
    width: 48px;
    min-width: 48px;
    min-height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 48px;
    border: 1.8px solid rgba(var(--background-color-rgb), 16%);
    cursor: pointer;
    color: var(--background-color-rgb);
}

.social:hover {
    background-color: rgba(var(--background-color-rgb), 100%);
    color: var(--text-color);
}

.playerIcon {
    transform: scale(1.5);
}

.subFooter {
    background-color: black;
    padding: 1rem 0;
}

.subFooterContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    font-size: 0.875rem;
}

.subFooterInfos {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logoMini {
    width: 44px;
    height: 44px;
}

#footer .sectionInner {
    padding: 0;
}

@media screen and (max-width: 1024px) {

    .sectionInner {
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .fieldGlasses {
        top: 3%;
    }

    .eduIllustration {
        right: -2rem;
        top: -2rem;
    }

    .problem {
        flex-wrap: wrap;
        width: 100%;
        gap: 8rem;
    }

    .problemContent {
        width: 100%;
    }

    .solutionContent {
        width: 100%;
    }

}

@media screen and (max-width: 768px) {

    .sectionInner {
        padding: 0;
    }

    .title {
        font-size: 3rem;
        width: 80%;
    }

    .subtitle {
        font-size: 1rem;
        width: 100%;
    }

    .angleShape {
        max-width: 70vw;
        max-height: 50vw;
    }

    .fieldGlasses {
        width: 168px;
        height: 168px;
        top: 10px;
        right: 0%;
    }

    .banner {
        padding-top: 0rem;
        padding-bottom: 0;
    }

    .blob {
        width: 400px;
        height: 400px;
    }

    .problem {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100%;
        padding: 3rem 1rem;
        gap: 6rem;
    }

    .problemContent,
    .solutionContent {
        width: 100%;
        padding-left: 0;
    }

    .solutionImage {
        display: flex;
        max-height: 100px;
        max-width: 100px;
    }

    .actionButton {
        padding: 1rem 2rem;
        font-size: 1.2rem;
    }

    .playerIcon {
        transform: scale(1.5);
    }

    .unicornBlob {
        display: none;
    }

    .unicornBlob.inline {
        display: flex;
        top: 0;
    }

    .whoContent {
        width: 100%;
    }

    .masters{
        width: 100%;
    }

    .mastersContent {
        width: 100%;
    }

    .peopleBlob {
        display: none;
    }

    .peopleBlob.inline {
        top: 0;
        display: flex;
    }

    .footer {
        flex-wrap: wrap;
        gap: 2rem;
    }

    .footerWho,
    .footerLinks,
    .footerContact,
    .footerSocial {
        width: 45%;
        padding: 0;
    }

    .subFooterContent {
        padding: 0 1.5rem;
    }

}


@media screen and (max-width: 540px) {

    .container {
        padding: 0;
    }

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 2rem 1rem;
        flex-direction: column;
    }

    .sectionInner>div {
        width: 100%;
    }

    .sectionTitle {
        font-size: 1.25rem;
        white-space: wrap;
    }

    .angleShape {
        max-width: 80vw;
        max-height: 80vw;
    }

    .fieldGlasses {
        width: 148px;
        height: 148px;
        top: 30px;
        right: -15%;
        display: none;
    }

    #main {
        padding-bottom: 0;
    }

    .banner {
        padding: 1rem 0 0 0;
    }

    .title {
        font-size: 2.25rem;
        gap: 0.4rem;
        width: 95%;
    }

    .subtitle {
        width: 100%;
    }

    .actionButton {
        padding: 1rem 2rem;
        font-size: 1rem;
    }

    .playerWrapper {
        padding: 0;
    }

    #problems .sectionInner {
        padding-bottom: 0;
    }

    .problems {
        padding-top: 0;
        padding-bottom: 0;
    }

    .problem {
        padding: 4rem 0;
        gap: 6rem;
    }

    .problemContent {
        z-index: 1;
    }

    .masters {
        padding-top: 2rem;
    }

    .unicornBlob.inline {
        float: none;
        width: 100%;
    }

    .peopleBlob.inline {
        float: none;
        width: 100%;
    }

    .whoContent {
        z-index: 1;
        padding: 0 1rem;
    }

    #footer .sectionInner {
        padding: 0 2rem;
    }

    .footer {
        flex-direction: column;
        gap: 1rem;
    }

    .footerWho,
    .footerContact,
    .footerLinks,
    .footerSocial {
        width: 100%;
    }

    .socials {
        justify-content: flex-start;
    }

    .footerWho {
        gap: .5rem;
    }

    .logo {
        display: flex;
        width: fit-content;
        height: 35px;
        transform: translateX(-8px);
    }

    .playerIcon {
        transform: scale(1);
    }

    .subFooterContent {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        font-size: 0.875rem;
    }
}