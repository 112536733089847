.containerWrapper {
    --accentColor: var(--primary);
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgba(var(--text-color-rgb), 6%);
}

.container {
    display: flex;
    background-color: rgba(var(--text-color-rgb), 6%);
    gap: 1px;
    width: fit-content;
    height: fit-content;
    /* border: 1px solid rgba(var(--text-color-rgb), 6%); */
    overflow: hidden;
    flex-wrap: wrap;
}

.containerWrapper.active {
    border: 1px solid rgba(var(--primary-rgb), 100%);
}

.lessonContainer {
    display: flex;
    background-color: var(--background-color);
    max-width: 142px;
    width: 142px;
}

/* .lessonContainer.active {
    background-color: rgba(var(--primary-rgb), 4%)
} */

.lesson{
    padding: 1rem;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.lessonHour {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color);
    text-align: center;
    white-space: nowrap;
}

.lessonHour.professional {
    font-size: 0.875rem;
}

.lessonDay { 
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--accentColor);
    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
}

.remaining {
    display: flex;
    background-color: rgba(var(--text-color-rgb), 6%);
    justify-content: center;
    align-items: center;
    padding: .25rem 0;
    font-weight: 700;
    font-size: 0.75rem;
    color: var(--accentColor);
}

.remaining.active {
    background-color: var(--primary);
    color: white;
}