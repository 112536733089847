.cardInner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 1rem;
}

.cardHeader {
    display: flex;
    flex-direction: column;
}

.bookingImg {
    display: flex;
    flex: 1;
}

.expertImage {
    display: flex;
    width: 80%;
    object-fit: contain;
    margin: 0 auto;
}

.cardBody {
    display: flex;
    flex-direction: column;
    line-height: 150%;
}

.bookButton {
    font-size: 1.125rem;
    padding: 1rem 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.callButton {
    font-size: 1.125rem;
    padding: 1rem 4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cardActions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.loader {
    display: flex;
    flex-direction: column;
}

.callRow {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.admin {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    gap: .25rem;
}


@media screen and (max-width: 540px) {
    .expertImage {
        width: 100%;
    }

    .bookButton {
        width: 100%;
    }
}