.container {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: calc(100vh - 80px);
    height: calc(100vh - 80px);
    align-items: center;
    justify-content: center;
    background-color: var(--background-secondary-color);
    overflow-y: scroll;
}

.payment {
    display: flex;
    width: 100%;
    flex-direction: column;
    height: 100%;
    align-items: center;
    padding: 2rem 0;
}

.inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 1440px;
    align-items: center;
    padding: 0 3rem;
    position: relative;
}

.summary {
    width: 400px;
    min-width: 400px;
    display: flex;
    position: sticky;
    height: 100%;
    background-color: var(--background-color);
}

.paymentForm {
    width: 100%;
    max-width: 800px;
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: scroll;
    overflow-x: hidden;
}

.paymentForm::-webkit-scrollbar{
    display: none;
}

.paymentForm > div {
    width: 100%;
}

.spacer {
    display: flex;
    flex: 1;
}

.arrowIcon > g > path {
    fill: var(--primary) !important;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 1rem 0;
}

.spacer {
    display: flex;
    flex: 1;
}

.empty {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    max-width: 350px;
    text-align: center;
    gap: 1rem;
}

.cartPayment {
    display: flex;
    flex-direction: column;
}

.paymentError {
    background-color: var(--secondary);
    padding: .5rem 1rem !important;
    border-radius: 8px;
    margin-top: 1rem;
    color: white;
    font-size: 0.87rem;
}

.summary {
    display: flex;
    flex-direction: column;
}

.summaryTitle {
    width: 100%;
}

.title {
    font-size: 1.5rem;
    font-weight: 800;
    padding: 2rem 1rem;
}

.productContainer {
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.product {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.productThumbnail {
    width: 56px;
    height: 32px;
    border-radius: 4px;
    object-fit: cover;
}

.productInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    flex: 1;
    gap: .3rem;
}

.productName {
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 800;
}

.productPrices {
    display: flex;
    flex-direction: column;
}

.productPrice {
    font-size: 0.875rem;
    color: #BEBEBE;
    text-decoration: line-through;
    font-weight: 600;
    text-align: right;
}

.productDiscountedPrice {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color);
    text-align: right;
}

.total {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    align-items: center;
    justify-content: space-between;
}

.totalLabels {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
}

.vatLabel {
    font-size: 0.875rem;
    color: rgba(var(--text-color-rgb), 64%)
}

.totalPrice {
    font-weight: 800;
    font-size: 1.125rem;
    padding: 0 1rem;
}

.discount {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    background-color: #FF86520A;
    align-items: center;
    justify-content: space-between;
}

.discountLabel {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
}

.discountValue {
    font-size: 1.125rem;
    font-weight: 700;
    color: #FF8652;
    padding: 1rem;
}

.isPayingOverlay {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(var(--background-secondary-color-rgb), 70%);
}

.deleteButton {
    background-color: transparent;
    border: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 600;
    gap: .2rem;
    color: var(--secondary);
    padding: 0;
    cursor: pointer;
    display: flex;
}

.couponLabel {
    font-family: 'Work Sans';
    color: var(--text-color);
    font-size: 14.88px;
    font-weight: 400;
}

.couponInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.coupon {
    gap: .25rem;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
}

.goToCourses{
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.125rem;
    padding: 1rem 4rem;
}

.payButton {
    font-size: 1.125rem;
    padding: 1rem 4rem;
}

@media screen and (max-width: 1124px){

    .container{
        flex-wrap: wrap-reverse;
        height: 100%;
        min-height: 100vh;
        align-content: flex-end;
    }    

    .summary {
        position: relative;
        top: unset;
        height: fit-content;
        border-radius: 12px;
        width: 100%;
        margin: 1rem 3rem;
        min-width: 0;
    }
    
    .title{
        padding: 1rem;
    }

    .payment {
        height: fit-content
    }
}

@media screen and (max-width: 768px) {
    
    .inner {
        padding: 0 2rem;
    }

    .summary {
        margin: 1rem 2rem;
    }

    .actions {
        flex-wrap: wrap-reverse;
        justify-content: space-between;
        gap: 1rem;
    }

    .spacer {
        display: none;
    }

    .deleteButton {
        display: flex;
    }

}

@media screen and (max-width: 540px) {
    .inner {
        padding: 0 1rem;
    }

    .summary {
        margin: 1rem;
    }

    .actions {
        flex-direction: column-reverse;
        justify-content: center;
    }

    .payButton {
        font-size: 1.125rem;
        padding: 1rem;
        max-width: 100%;
        width: 100%;
    }

    .goToCourses {
        padding: 1rem;
        max-width: 100%;
        width: 100%;
    }
}