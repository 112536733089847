.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  color: var(--text-color);
  transition: width 0.5s ease-in;
}

.content.sidenav {
  width: calc(100% - 300px);
  transition: width 0.5s ease-in;
}

.loader-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.splide {
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
}

.splide__pagination {
  position: relative !important;
  padding: .5rem 0 !important;
  bottom: unset !important;
}

.splide__pagination__page.is-active {
  background: var(--primary) !important;
}

.easepick-wrapper {
  display: flex !important;
  justify-content: center !important;
}

.loader {
  --accent-color: var(--primary-color);
  width: 18px !important;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid var(--accent-color);
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.inlineMarkdown p {
  margin: 0 !important;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .content.sidenav {
    width: 100%;
    transition: width 0.5s ease-in;
  }

  .content>.easepick-wrapper {
    display: flex !important;
    width: 100% !important;
    overflow: scroll;
  }
}
