.container {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    position: relative;
    max-height: fit-content;
}

.cardInner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    max-height: fit-content;
    position: relative;
}

.cardHeader {
    display: flex;
    flex-direction: column;
}

.featured {
    display: flex;
    height: 100%;
    flex-direction: column;
    width: 100%;
}

.cardContent {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    container-name: 'carousel';
    container-type: inline-size;
}

.course {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}

.thumbnailWrapper {
    display: flex;
    position: relative;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem;
    overflow: hidden;
}

.thumbnail {
    display: flex;
    width: 100%;
    object-fit: contain;

}

.thumbnailOverlay {
    position: absolute;
    background-image: linear-gradient(to top, rgba(var(--text-color-rgb), 15%) 10%, transparent 30%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 4.8rem;
    height: 4.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 150%;
    opacity: 0.7;
}

.courseButton {
    padding: 1rem 4rem;
    font-size: 1.125rem;
    text-transform: uppercase;
}

.splide {
    max-width: calc(100%)
}

.arrows {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    gap: .5rem;
}

.arrowButton {
    aspect-ratio: 1;
    background-color: transparent;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrowButton:disabled > svg > path {
    stroke: rgba(var(--text-color-rgb), 12%);
}

.arrowButton > svg > path {
    stroke: var(--text-color);
}

.arrowButton.left{
    transform: rotate(-90deg);
}

.arrowButton.left > svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.arrowButton.right{
    transform: rotate(90deg);
}

.arrowButton.right > svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}


@media screen and (max-width: 768px) {

    .description {
        height: 5rem;
        -webkit-line-clamp: 2;
        line-clamp: 2;
    }

}

@media screen and (max-width: 540px) {
    .courseButton {
        width: 100%;
    }

}