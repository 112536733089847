.container {
    --collapsable-header-background: transparent;
    --collapsable-header-text-align: left; 
    --collapsable-icon-color: var(--text-color);
    --collapsable-header-padding: 0;
    --collapsable-header-font-size: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    height: fit-content;
    container-type: inline-size;
}

.container.expanded {
    overflow: visible;
}

.header {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    transition: .2s all ease-in-out;
    border: none;
    cursor: pointer;
    font-family: var(--primary-font);
    font-weight: 700;
    font-size: var(--collapsable-header-font-size);
    color: black;
    gap: .5rem;
    text-align: var(--collapsable-header-text-align);
    background-color: var(--collapsable-header-background);
    padding: var(--collapsable-header-padding);
}

.header.expanded {
    transition: .2s all ease-in-out;
}

.chevronIcon {
    transform: scaleY(-1);
    transition: all .2s ease-in-out;
    min-width: 20px;
    color: var(--collapsable-icon-color);
}

.chevronIcon.expanded {
    transition: all .2s ease-in-out;
    transform: scaleY(1);
}

.content {
    max-height: 0;
    transition: .2s all ease-in-out;
    display: flex;
    flex-direction: column;
}

.content.expanded {
    max-height: 1000px;
    transition: .2s all ease-in-out;
}

@media screen and (max-width: 768px) {

    .header {
        padding: 1rem;
        font-size: 1rem;
    }

    .content {
        flex-direction: column;
    }

    .argument {
        width: 100%;
    }

}

@media screen and (max-width: 540px) {}