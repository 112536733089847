.none {
    display: none;
}

.cardHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    padding: 1rem 1.5rem;
    align-items: center;
    gap: .7rem;
}

.icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon.lesson {
    background-color: #DFF2FF;
}

.icon.material {
    background-color: #DFFFEA;
}

.icon.exercise {
    background-color: #FFF8DF;
}

.icon.communication>img {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 18px;
    padding: 1px;
    border: 1px solid var(--secondary);
}

.info {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.title {
    font-size: 1rem;
    font-weight: 700;
    color: var(--text-color)
}

.subtitle {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(var(--text-color-rgb), 72%);
    display: flex;
    align-items: center;
    gap: .2rem;
    font-family: var(--primary-font);
}

.clockIcon {
    height: 12px;
    width: 12px;
}

.clockIcon>g>path {
    stroke: rgba(var(--text-color-rgb), 72%)
}

.date {
    font-size: 0.875rem;
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 32%);
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    gap: 0.4rem;
}

.date>.clockIcon {
    width: 20px;
    height: 20px;
}

.date>.clockIcon>g>path {
    stroke: rgba(var(--text-color-rgb), 32%);
}

.lessonEnded {
    font-weight: 800;
    font-size: 0.75rem;
    border: 1.5px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 8px;
    padding: .3rem .5rem;
}

.lessonCard {
    display: flex;
    width: 100%;
}

.content {
    padding: 1.5rem;
}

.thumbnailContainer {
    display: flex;
    position: relative;
}

.thumbnail {
    object-fit: cover;
    border-radius: 12px;
    aspect-ratio: 16/9;
    width: 100%;
    max-height: 350px;
    position: relative;
}

.thumbnail.ended {
    opacity: 0.5;
    background-color: var(--background-color);
}

.playButton {
    cursor: pointer;
    height: 80px;
    width: 80px;
    border-radius: 40px;
    background-color: var(--background-color);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary);
}

.description {
    font-size: 0.875rem;
    padding-top: 1rem;
    color: var(--text-color);
    font-family: var(--primary-font);

}

.description.communication {
    padding: 0;
    white-space: pre-line;
}

.materials {
    display: flex;
    flex-direction: row;
    flex-basis: 50%;
    flex-wrap: wrap;
}

.materialWrapper {
    display: flex;
    flex-basis: 100%;
    padding: .5rem;
}

.dropzone {
    background-color: rgba(var(--tertiary-rgb), 8%);
    border: 2px dashed #4CB4FF32;
    border-radius: 12px;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex: 1;
    margin-top: 1rem;
}

.dropzoneLabel {
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 72%);
    text-align: center;
}

.selectFileButton {
    background-color: var(--tertiary);
}


.fileWrapper {
    display: flex;
    flex-basis: 50%;
    padding: 0 .5rem;
}


.fileInfo {
    display: flex;
    flex-direction: column;
    gap: .3rem;
}

.fileName {
    font-weight: 700;
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.2rem;
    /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
}

.fileSize {
    font-weight: 700;
    font-size: 0.75rem;
    color: rgba(var(--text-color-rgb), 48%);
}

.deleteFile {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgba(var(--secondary-rgb), 10%);
    border-radius: 10px;
    width: 32px;
    height: 32px;
    background: none;
    cursor: pointer;
}

.deleteIcon {
    width: 20px;
    height: 20px;
    min-width: 20px;
    min-height: 20px;
}

.deleteIcon>path {
    fill: var(--secondary);
}

@media (hover: hover) {


    .selectFileButton:hover {
        color: var(--tertiary);
        background-color: var(--background-color);
        box-shadow: inset 0px 0px 0px 2px var(--tertiary);
        transition: .2s ease-in-out all;
    }

    .selectFileButton:hover>svg>g>path {
        fill: var(--tertiary);
        stroke-width: 0;
    }

}


@media screen and (max-width: 1280px) {
    .materialWrapper {
        flex-basis: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .materialWrapper {
        flex-basis: 100%;
    }
}


@media screen and (max-width: 768px) {
    .playButton {
        height: 60px;
        width: 60px;
    }
}

@media screen and (max-width: 540px) {

    .materialWrapper {
        flex-basis: 100%;
    }
}
