.container {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background-color: white;
    overflow: hidden;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
}

.expired {
    filter: grayscale(1);
    opacity: 0.85;
}

.container.hover {
    transition: all .2s ease;
}

.thumbnail {
    display: flex;
    width: 100%;
    background-size: contain;
    aspect-ratio: 16/9;
    background-repeat: no-repeat;
    position: relative;
}

.thumbnailOverlay {
    position: absolute;
    background-image: linear-gradient(to top, rgba(var(--text-color-rgb), 15%) 10%, transparent 30%);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    left: 0;
}

.thumbnail>img {
    display: flex;
    object-fit: cover;
    width: 100%;
}

.metas {
    position: absolute;
    bottom: .5rem;
    left: 1rem;
    color: white;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.meta {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.2rem;
    font-weight: 600;
    font-size: 0.8rem;
}

.meta>svg>path {
    fill: white;
}

.info {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    /* (line-height * numero di righe) + eventuali padding/margini */
    overflow: hidden;
    text-overflow: ellipsis;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: rgba(var(--text-color-rgb), 12%);
}

.spacer {
    display: flex;
    flex: 1;
}

.purchase {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: .5rem;
}

.discount {
    display: flex;
    flex-direction: row;
    color: #FF8652;
    font-weight: 500;
    justify-content: center;
    padding-bottom: .5rem;
}

.discount>div {
    text-decoration: line-through;
    padding-left: .3rem;
    font-weight: 700;
}

@media (hover:hover) {
    .container.hover:hover {
        transform: translateY(-6px);
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.16);
    }
}
