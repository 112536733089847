.container {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    position: relative;
    max-height: fit-content;
}

.cardInner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 1rem;
    max-height: fit-content;
    position: relative;
}

.cardContent {
    container-name: cardInner;
    container-type: inline-size;
    width: 100%;
}

.lesson {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: 1rem;
    gap: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
}

.lessonInner {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.thumbnail {
    max-width: 100px;
    border-radius: 6px;
    object-fit: contain;
}

.lessonInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0.5rem;
}

.info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1rem
}

.names {
    display: flex; 
    flex-direction: column;
    gap: 0.25rem;
}

.courseName {
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    color: var(--secondary);
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.5rem; 
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.lessonName {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.5rem; 
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
}

.progressContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.progressInfo {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.numbers {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.number {
    display: flex;
    flex-direction: row;
    gap: 0.25rem;
    align-items: center;
    font-weight: 700;
}

.percentage {
    color: var(--tertiary);
    font-size: 1rem;
    font-weight: 700;
}

.progressBar {
    background-color: rgba(0,0,0,0.1);
    height: 10px;
    width: 100%;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
}

.progressFill {
    position: absolute;
    left: 0;
    top: 0;
    height: 10px;
    border-radius: 10px;
    background: linear-gradient(to right, #16C0FB 0%, var(--primary) 100%);
}

.date {
    font-size: 0.75rem;
    color: rgba(var(--text-color-rgb), 66%);
}

.questionIcon {
    display: flex;
    width: 16px;
    height: 16px;
    color: var(--secondary);
    cursor: pointer;
    text-transform: none;
    font-weight: 500;
}

.arrows {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    gap: .5rem;
}

.arrowButton {
    aspect-ratio: 1;
    background-color: transparent;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrowButton:disabled > svg > path {
    stroke: rgba(var(--text-color-rgb), 12%);
}

.arrowButton > svg > path {
    stroke: var(--text-color);
}

.arrowButton.left{
    transform: rotate(-90deg);
}

.arrowButton.left > svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.arrowButton.right{
    transform: rotate(90deg);
}

.arrowButton.right > svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.empty {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 350px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.illustration {
    display: flex;
    object-fit: contain;
    max-width: 220px;
    max-height: 220px;
}