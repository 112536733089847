.classesRow {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-height: 160px;
}

.empty {
    min-height: 0;
}

.groups {
    /* position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;
    max-width: 100%;
    width: 100%;
    scrollbar-width: none;
    box-sizing: border-box; */
}

.groupsInner {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    overflow-x: auto;
    max-width: 100%;
    width: 100%;
    scrollbar-width: none;
    box-sizing: border-box;
}


.groups::-webkit-scrollbar {
    display: none;
}

.groupCard {
    display: flex;
    margin-right: 0.5rem;
    cursor: pointer;
    flex-shrink: 0;
}