.section {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    height: fit-content;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex: 1;
}

.image {
    display: flex;
    width: 100%;
    height: 100%;
    position: relative;
}

.video {
    display: flex;
    width: 100%;
    position: relative;
}

.carousel {
    width: 100%;
}

.collapsable {
    width: 100%;
}

.player {
    overflow: hidden;
}

.text {
    display: flex;
    width: 100%;
    height: fit-content;
    position: relative;
}

.markdown {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
}

.markdown p {
    margin: 0;
}

.button {
    width: 100%;
    display: flex;
    position: relative;
    height: fit-content;
}

@media screen and (max-width: 768px) {

    .column {
        height: fit-content;
    }

    .video {
        width: 100%;
        flex-basis: 100%;
    }
}