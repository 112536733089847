.container {
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    width: 100%;
    height: 100%;
    position: relative;
}

.cardInner {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding: 1rem;
    max-height: fit-content;
    position: relative;
}

.cardContent {
    display: flex;
    flex: 1;
    container-name: cardInner;
    container-type: inline-size;
    width: 100%;
    height: 100%;
}

.communication {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    overflow: hidden;
    height: 100%;
    flex: 1;
}

.communicationHeader {
    display: flex;
    flex-direction: row;
    background-color: #fefaed;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    align-items: center;
    padding: 1rem;
    gap: 0.5rem;
}

.picture {
    width: 60px;
    object-fit: contain;
    border-radius: 6px;
}

.name {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    max-height: 1.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.date, .module {
    font-size: 0.875rem;
    color: rgba(var(--text-color-rgb), 66%)
}

.module {
    font-weight: 700;
}

.communicationContent {
    padding: 1rem;
    height: 316px;
    max-height: 316px;
    overflow: auto;
}

.empty {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-height: 350px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.illustration {
    display: flex;
    object-fit: contain;
    max-width: 220px;
    max-height: 220px;
}

.splide {
    height: 100%;
}


.arrows {
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: fit-content;
    gap: .5rem;
}

.arrowButton {
    aspect-ratio: 1;
    background-color: transparent;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 20px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrowButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.arrowButton:disabled > svg > path {
    stroke: rgba(var(--text-color-rgb), 12%);
}

.arrowButton > svg > path {
    stroke: var(--text-color);
}

.arrowButton.left{
    transform: rotate(-90deg);
}

.arrowButton.left > svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}

.arrowButton.right{
    transform: rotate(90deg);
}

.arrowButton.right > svg {
    transform: translateY(-.5px);
    width: 12px;
    height: 12px;
    min-width: 12px;
    min-height: 12px;
}