.container {
    background-color: var(--background-color);
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem;
    gap: 1rem;
    border-radius: 1rem;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
}

.courseLeft {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    /* padding: 0 1rem; */
}

.courseRight {
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* padding: 0 1rem; */
}

.description {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 4.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

.featureRow {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    flex-wrap: wrap;
    padding: .5rem 0;
}

.feature {
    display: flex;
    font-weight: 600;
    color: rgba(var(--text-color-rgb), 0.6);
    align-items: center;
    line-height: 1.5rem;
    gap: .3rem;
    white-space: nowrap;
    font-family: var(--primary-font);
}

.feature>svg {
    height: 20px;
    width: 20px;
}

.feature>svg>g>path {
    stroke: rgba(var(--text-color-rgb), 0.6);
}

.feature>svg>path {
    stroke: rgba(var(--text-color-rgb), 0.6);
}

.priceRow {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    flex-wrap: wrap-reverse;
}

.discount {
    display: flex;
    flex-direction: row;
    color: #FF8652;
    font-weight: 500;
    justify-content: center;
    flex-wrap: nowrap;
}

.discount>div {
    text-decoration: line-through;
    padding-right: .3rem;
    font-weight: 700;
}

.action {
    padding: 1rem 3rem;
    width: 100%;
    font-size: 1.125rem;
}

.actionRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    padding: .5rem 0;
    flex-wrap: wrap;
}

.actionRow>div {
    display: flex;
    width: 50%;
}

.thumbnail {
    aspect-ratio: 16/9;
    object-fit: cover;
    display: flex;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    padding-bottom: 1rem;
}

.thumbnailMobile {
    aspect-ratio: 16/9;
    object-fit: cover;
    display: none;
    width: 100%;
    border-radius: 12px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
}

.overlay {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0) 30%);
}

@media screen and (max-width: 760px) {
    .container{
        flex-direction: column-reverse;
        padding: 0;
    }

    .thumbnail {
        display: none;
    }

    .thumbnailMobile {
        display: flex;
    }

    .courseLeft, .courseRight{
        width: 100%;
    }

    .courseLeft {
        padding: 1rem;
    }

    .actionRow > div {
        width: 100%;
    }

    .action {
        width: 100%;
        padding: 1rem !important; 
    }
}