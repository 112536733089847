.container {
    background-color: var(--background-color);
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 8px;

    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    justify-content: space-between;
    padding: .8rem 1rem;
    color: var(--text-color)
}

.left {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    flex-grow: 1;
}

.material {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 40%;
}

.name {
    font-weight: 700;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    max-height: 3.6em;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: anywhere;
}

.size {
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 48%)
}

.icon {
    width: 1rem;
    height: 1rem;
}

.time {
    display: flex;
    align-items: center;
    gap: .3rem;
    color: rgba(var(--text-color-rgb), 32%);
    font-size: .875rem;
    font-weight: 700;
    white-space: nowrap;
    width: 15%;
    justify-content: flex-end;
}

.thumbnail {
    border-radius: 6px;
    height: 56px;
}

.action {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    justify-content: flex-end;
    align-items: center;
}

@media screen and (max-width: 540px) {
    .container {
        flex-direction: column;
    }
    .action {
        justify-content: space-between;
    }
    .material {
        width: 100%;
    }
    .time{
        justify-content: flex-start;
    }
}