.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: var(--background-secondary-color);
}

.section {
    background-color: var(--background-secondary-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    padding: 2rem 0;
}

.sectionInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1440px;
    padding: 0 3rem;
    flex-wrap: wrap;
}

.header{
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.summary {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    border-radius: 12px;
    width: 100%;
    min-height: 75vh;
}

.summaryTitle {
    width: 100%;
}

.title {
    font-size: 1.5rem;
    font-weight: 800;
    padding: 2rem 1rem;
}

.productContainer {
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    width: 100%;
    flex-direction: row;
    align-items: center;
}

.product {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
}

.productThumbnail {
    width: 56px;
    height: 32px;
    border-radius: 4px;
    object-fit: cover;
}

.productInfo{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    flex: 1;
    gap: .3rem;
}

.productName {
    color: var(--text-color);
    font-size: 1rem;
    font-weight: 800;
}

.productPrices {
    display: flex;
    flex-direction: column;
}

.productPrice {
    font-size: 0.875rem;
    color: #BEBEBE;
    text-decoration: line-through;
    font-weight: 600;
    text-align: right;
}

.productDiscountedPrice {
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color);
    text-align: right;
}

.total {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    align-items: center;
    justify-content: space-between;
}

.totalLabels {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
}

.vatLabel {
    font-size: 0.875rem;
    color: rgba(var(--text-color-rgb), 64%)
}

.totalPrice {
    font-weight: 800;
    font-size: 1.125rem;
    padding: 0 1rem;
}

.status {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    /* background-color: rgba(var(--primary-rgb), 4%); */
    align-items: center;
    justify-content: space-between;
}

.statusLabel {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
}

.statusValue {
    font-size: 1.125rem;
    font-weight: 700;
    /* color: var(--primary); */
    padding: 1rem;
}

.spacer {
    display: flex;
    flex: 1;
}

.actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 3rem 0;
}

.discount {
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    align-items: center;
    justify-content: space-between;
}

.discountLabel {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 700;
}

.discountValue {
    font-size: 1.125rem;
    font-weight: 700;
    color: #FF8652;
    padding: 1rem;
}

@media screen and (max-width: 768px) {

    .section {
        padding: 0;
    }

    .sectionInner {
        padding: 2rem;
        flex-direction: column;
    }

    .header{
        flex-direction: column;
        align-items: flex-start;
    }

    .summary {
        min-height: 0;
    }
}

@media screen and (max-width: 540px) {

    .sectionInner {
        padding: 1rem;
    }

}