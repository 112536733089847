.container {
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    height: 80px;
    width: 100%;
    z-index: 9;
    border-bottom: 0px solid rgba(var(--text-color-rgb), 12%);
    align-items: flex-start;
    justify-content: center;
    padding: 0 3rem;
    transition: all .2s ease-in-out;
}

.filled {
    background-color: var(--sidenav-color);
    transition: all .2s ease-in-out;
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
}

.header {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.logo {
    width: 7rem;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
    display: flex;
}

.menu {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 0 3rem;
}

.menuButton {
    background: transparent;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 800;
    font-family: var(--primary-font);
    color: var(--text-color);
}

.menuButton.active {
    color: var(--primary);
    font-weight: 700;
}

.spacer {
    display: flex;
    flex: 1;
}

.userButton {
    display: flex;
    background-color: transparent;
    border: none;
    /* border-top: 1px solid rgba(var(--text-color-rgb), 12%); */
    padding: 1rem .5rem;
}

.userContainer {
    background: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    position: relative;
}

.userAvatar {
    object-fit: cover;
    padding: 3px;
    border-radius: 42px;
    height: 48px;
    width: 48px;
    border: 1.26px solid var(--primary);
    background: rgba(var(--text-color-rgb), 12%);
    position: relative;
}

.userAvatarContainer {
    position: relative;
}

.userAvatarContainer .warningBadge {
    height: 20px;
    width: 20px;
}

.warningBadge {
    display: flex;
    position: absolute;
    color: var(--sf-red);
    top: -0.25rem;
    right: -0.25rem;
    background-color: white;
    border-radius: 50%;
}

.userName {
    font-family: var(--primary-font);
    font-size: 1rem;
    font-weight: 600;
    color: var(--text-color)
}

.iconButtons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.iconButton {
    width: 50px;
    height: 50px;
    min-width: 50px;
    min-height: 50px;
    border: none;
    background-color: transparent;
    margin: 0 .3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: black;
}


.iconBadge {
    position: absolute;
    top: .5rem;
    right: 1.5rem;
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
}

.badge {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: var(--secondary);
    color: white;
    font-weight: 700;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 2px solid white;
}

@media (hover:hover) {

    .menuButton:hover {
        color: var(--primary)
    }
}

@media screen and (max-width: 768px) {
    
    .container {
        height: 100%;
        height: --webkit-fill-available;
        min-height: 70px;
        max-height: 70px;
        padding: 0;
        justify-content: flex-start;    
        border-bottom: 0px solid;
    }

    .container.open {
        position: fixed;
        top: 0;
        width: 100%;
        padding: 0;
        max-height: unset;
    }

    .header {
        height: 70px;
        min-height: 70px;
        padding: 0 1rem;
        border-bottom: 0px solid rgba(var(--text-color-rgb), 12%);
    }

    .container.filled > .header {
        border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    }

    .content {
        height: 0px;
        transition: .2s all ease-in-out;
        visibility: hidden;
        position: relative;
        width: 100%;
        background-color: var(--sidenav-color);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 1rem;
        z-index: 9;
    }
    
    .content.open > * {
        width: 100%;
    }

    .icon {
        display: none;
    }

    .content.open {
        visibility: visible;
        opacity: 1;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;
        height: 100%;
    }

    .logo {
        width: auto;
        height: 38px;
    }

    .icon {
        display: flex;
        width: 20px;
        height: 20px;
    }

    .menuButton {
        background-color: transparent;
        display: flex !important;
        align-items: center !important;
        gap: 0.5rem;
        font-size: 1rem;
        font-weight: 600;
        font-family: var(--primary-font);
        text-decoration: none;
        color: rgba(var(--text-color-rgb), 100%);
        cursor: pointer;
        width: 100%;
        padding: 1rem;
        position: relative;
    }
    
    .menuButton.active {
        color: var(--primary) !important;
        background: rgba(var(--primary-rgb), 10%) !important;
        border-radius: .75rem !important;
        font-weight: 800;
    }

    .loginIcon {
        height: 20px;
        width: 20px;
    }

    .loginIcon > path {
        fill: white;
    }

    .userAvatar {
        height: 32px;
        width: 32px;
    }

}