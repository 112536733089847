.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0 auto;
  background-color: var(--background-secondary-color);
}

.section {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  align-items: center;
  padding: 2rem 0;
}

.sectionInner {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1368px;
  padding: 0 3rem;
  gap: 1.25rem;
}

.courseTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.columns {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 1rem;
}

.courseCard {
  flex-basis: 40%;
  height: fit-content;
}

#communications {
  container-type: inline-size;
  height: 100%;
}

#communications>div {
  height: 100%;
}

.communications {
  flex-basis: 60%;
  display: flex;
  height: 100%;
}

.cardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.cardTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cardTitle {
  font-size: 1.125rem;
  font-weight: 800;
}

.activity {
  border-radius: 14px;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.activityHeader {
  background-color: #FEFAED;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
  gap: .5rem;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
}


.activityTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;
  font-weight: 700;
  font-size: 0.875rem;
}

.activityInfo {
  display: flex;
  flex-direction: column;
  gap: .2rem;
}

.profile {
  font-weight: 700;
  font-size: 0.875rem;
}

.clock {
  color: rgba(var(--text-color-rgb), 32%);
  display: flex;
  flex-direction: row;
  gap: .3rem;
  align-items: center;
}

.clock>svg {
  width: 16px;
  height: 16px;
}

.activityContent {
  padding: 1rem;
  flex-grow: 1;
  font-weight: 500;
  background-color: var(--background-color);
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
}

.noCommunications {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.sectionTitle {
  font-weight: 800;
  font-size: 1.5rem;
}

.searchBar {
  max-width: 256px;
}

.modules {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.profilePic {
  width: 32px;
  height: 32px;
  border-radius: 100px;
  object-fit: cover;
  border: 1.5px solid var(--secondary);
  padding: 2px;
  background-color: var(--background-color);
}

.arrows {
  position: absolute;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: fit-content;
  gap: .5rem;
}

.arrowButton {
  aspect-ratio: 1;
  background-color: transparent;
  border: 1px solid rgba(var(--text-color-rgb), 12%);
  border-radius: 20px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.arrowButton:disabled>svg>path {
  stroke: rgba(var(--text-color-rgb), 12%);
}

.arrowButton>svg>path {
  stroke: var(--text-color);
}

.arrowButton.left {
  transform: rotate(-90deg);
}

.arrowButton.left>svg {
  transform: translateY(-.5px);
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}

.arrowButton.right {
  transform: rotate(90deg);
}

.arrowButton.right>svg {
  transform: translateY(-.5px);
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
}

/* @media screen and (max-width: 1024px) {
  .columns {
    flex-direction: column;
  }
} */

@media screen and (max-width: 768px) {

  .section {
    padding: 0;
  }

  .sectionInner {
    padding: 1rem;
  }

  .columns {
    flex-direction: column;
  }
}

@media screen and (max-width: 540px) {

  .header {
    flex-direction: column;
    align-items: start;
    gap: .5rem;
  }

  .searchBar {
    width: 100%;
    max-width: none;
  }
}