.container{
    display: flex;
    flex-direction: column;
    padding: 1rem 3rem;
    width: 100%;
    background-color: var(--background-secondary-color);
    gap: 1rem;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
}

.containerRow{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    max-width: 1200px;
}

.columnLeft{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.columnRight{
    display: flex;
    flex-direction: column;
    width: 70%;
    gap: 1rem;
}

.row{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    max-width: 1440px;
    flex-wrap: wrap;
    height: fit-content;
    padding: 1rem;
}

.column{
    display: flex;
    flex-direction: column;  
    gap: 1rem; 
}

.profileCard{
    display: flex;
    flex-direction: column;
    padding: 2rem 1rem;
    align-items: center;
    background: var(--background-color);
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 16px;
    padding: 3rem 1rem 1rem 1rem;
}

.profilePic{
    height: 100px;
    width: 100px;
    border-radius: 50px;
}

.userName{
    font-family: var(--primary-font);
    font-weight: 800;
    font-size: 1.1rem;
}

.profileInfo{
    padding: 2rem 0rem 2rem 0;
    width: 100%;
}

.profileRow{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .7rem 0;
    gap: 2rem;
    width: 100%;
    overflow: hidden;
}

.profileLabel{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: var(--secondary-font);
    font-weight: 500;
    font-size: 0.8rem;
    color: rgba(var(--text-color-rgb), 40%);
    gap: .2rem;
    width: 30%;
}

.profileRowValue{
    line-height: 1.2rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 240px;
    font-weight: 600;
}


.surveyCard{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.surveyInner{
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.surveyButton{
    display: flex;
    height: 40px;
    background-color: transparent;
    border: 1px solid rgba(var(--primary-rgb), 40%);
    font-family: var(--primary-font);
    font-weight: 700;
    color: rgba(var(--primary-rgb), 100%);
    white-space: nowrap;
    padding: 0 1rem;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    gap: .5rem;
}

.surveyData {
    width: 50%;
    padding: 1rem 0;
}

.surveyChart{
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.persChart {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.preferenceCard {
    width: 100%;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.preferenceInner {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
}

.preference {
    display: flex;
    flex-direction: column;
    background-color: var(--background-secondary-color);
    border-radius: 8px;
    padding: 1rem;
    flex-grow: 1;
}

.preferenceLabel {
    font-size: 0.875rem;
    font-weight: 500;
    color: rgba(var(--text-color-rgb), 40%);
    display: flex;
    align-items: center;
}

.preferenceValue {
    font-size: 1rem;
    font-weight: 800;
    color: rgba(var(--text-color-rgb), 88%);
    display: flex;
    align-items: center;
    gap: 0.25rem;
    font-family: var(--primary-font);
}

.coursesCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.chartData{
    display: flex;
    flex-direction: column;
    width: 30%;
}

.chartCell{
    background: rgba(var(--text-color-rgb), 4%);
    padding: .5rem 1rem;
    border-radius: 8px;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chartRow{
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    width: 100%;
}

.chartColor{
    height: 14px;
    width: 14px;
    border-radius: 30px;
}

.chartColumn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.chartDataTitle{
    font-family: var(--primary-font);
    font-weight: 700;
}
.chartDataSubitle{
    font-family: var(--primary-font);
    font-weight: 600;
    font-size: 0.8rem;
    flex: 1;
    width: 100%;
}

.circularChartColumn{
    display: flex;
    flex-direction: column;
    width: 45%;
    gap: 1rem;
}

.changeButton{
    display: flex;
    height: 30px;
    background-color: transparent;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    font-family: var(--primary-font);
    font-weight: 700;
    color: rgba(var(--text-color-rgb), 64%);
    white-space: nowrap;
    padding: 0 .5rem;
    border-radius: 8px;
    cursor: pointer;
    align-items: center;
    gap: .25rem;
    justify-content: center;
}

.carreersColumn{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.careers{
    display: flex;
    gap: 1rem;
    flex-direction: column;  
    flex: 1;
    padding: 1rem 0;
}

.goBackButton{
    background-color: transparent;
    color: var(--primary);
    font-family: var(--primary-font);
    font-weight: 800;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    gap: 0.5rem;
}

.editProfileCard{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.editProfileRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.error{
    color: red;
    display: flex;
    width: 100%;
    text-align: left;
    font-weight: 600;
}

.editProfileSaveRow{
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
}

.editPictureButton{
    background-color: var(--tertiary);
}

.editPictureDropzone{
    background-color: rgba(var(--tertiary-rgb), 8%);
    border: 2px dashed #4CB4FF32;
    border-radius: 12px;
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    flex: 1;
}

.editPictureLabel{
    font-family: var(--primary-font);
    font-weight: 500;
    font-size: 1rem;
    color: rgba(var(--text-color-rgb), 72%);
    max-width: 200px;
    text-align: center;
}

.imageCrop{
    width: 100%;
    height: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 12px;
}

.logout {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.75rem;
    color: var(--text-color);
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 3rem;
    padding: .5rem 2rem;
    font-weight: 700;
    background-color: transparent;
    width: fit-content;
    gap: .5rem;
    cursor: pointer;
    text-transform: uppercase;
    transition: all .2s ease-in-out;
}

.logout:hover {
    border: 1px solid rgba(var(--secondary-rgb), 79%);
    color: var(--secondary);
    transition: all .2s ease-in-out;
}

.macrosCard {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 1rem;
}

.macros {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
}

.macro {
    display: flex;
    flex-direction: column;
    flex-basis: 48%;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
    border-radius: 1rem;
    overflow: hidden;
    width: 100%;
}

.macroHeader {
    border-bottom: 1px solid rgba(var(--text-color-rgb), 12%);
    height: 180px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem;
    flex-direction: column;
}

.macroTitle {
    font-family: var(--primary-font);
    font-size: 1.75rem;
    font-weight: 900;
}

.macroAction {
    padding: 1rem;
}

.noPersonality {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
}

.alert {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: rgba(var(--sf-red-rgb), 0.1);
    border: 1px solid  rgba(var(--sf-red-rgb), 0.5);
    border-radius: 12px;
    gap: .5rem
}

.alert.success {
    background-color: rgba(var(--sf-green-rgb), 0.1);
    border: 1px solid  rgba(var(--sf-green-rgb), 0.5);
}

.alertDescription {
    white-space: pre-line;
    font-size: .75rem;
    opacity: 0.85;
}

.ordersCard {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
}

.order {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
    align-items: center;
    cursor: pointer;
    padding: 0 1rem;
    border-radius: 1rem;
}

.orderThumbnails {
    display: flex;
    position: relative;
    width: 80px;
    height: 60px;
    max-width: 80px;
    max-height: 60px;
}

.orderThumbnail {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    object-fit: contain;
    width: 100%;
    background: var(--background-color);
    border-radius: 6px;
    border: 1px solid rgba(var(--text-color-rgb), 12%);
}

.orderDetails {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
}

.orderNo {
    font-weight: 700;
}

.orderDate {
    opacity: 0.5;
    font-size: 0.75rem;
}

@media (hover: hover) {

    
    .editPictureButton:hover{
        color: var(--tertiary);
        background-color: var(--background-color);
        box-shadow:inset 0px 0px 0px 2px var(--tertiary);
        transition: .2s ease-in-out all;
    }

    .editPictureButton:hover > svg > g > path {
        fill: var(--tertiary);
        stroke-width: 0;
    }

    .order:hover {
        background-color: rgba(var(--primary-rgb), 22%);
    }

}


@media screen and (max-width: 1440px) {
    .containerRow{
        flex-direction: column;
    }

    .columnLeft{
        width: 100%;
    }

    .columnRight{
        width: 100%
    }

    .profileRowValue{
        max-width: none;
    }

    .surveyChart{
        width: 100%;
    }
}

@media screen and (max-width: 1024px){

    .surveyInner{
        flex-direction: column;
        align-items: center;
    }

    .surveyData{
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .surveyChart{
        width: 100%;
    }

    .circularChartColumn{
        width: 100%;
    }
    .carreer{
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .containerRow{
        flex-direction: column;
    }

    .columnRight, .columnLeft{
        width: 100%
    }

    .profileRowValue{
        max-width: none;
    }
    
    .surveyChart{
        width: 90%;
    }

    .surveyData{
        flex-direction: column;
    }

    .preferenceInner {
        flex-direction: column;
        gap: 1rem;
    }
}

@media screen and (max-width: 540px) {

    .container{
        padding: 1rem;
    }

    .profileCard{
        flex-direction: column;
    }

    .profileRow{
        gap: 1rem;
    }

    .profileRowValue{
        max-width: 220px;
    }
    
    .surveyChart{
        max-width: 300px;
    }

    .persChart{
        flex-direction: column;
    }

    .surveyChart {
        max-width: 100%;
        width: 100%;
    }

    .macro {  
        flex-basis: 100%;  
    }
}
