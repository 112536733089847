.container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.subtitle {
    font-weight: 400;
    color: rgba(var(--text-color-rgb), 0.72);
}

.row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.entry {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: .5rem;
}

.question {
    font-weight: 800;
}

.textAreaContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.textArea {
    border: none;
    padding: .5rem 0;
    display: flex;
    width: 100%;
    /* min-width: 512px; */
    border-radius: 14px;
    background-color: transparent;
    resize: none;
    font-family: var(--primary-font);
    font-size: 1rem;
    font-weight: 600;
    flex: 1 1;
    background-color: transparent;
    color: var(--text-color);
    min-height: 128px;
}

.textArea.small {
    min-height: 96px;
}

.textArea:focus {
    outline: none;
}

.textArea::placeholder {
    color: rgba(var(--text-color-rgb), 12%)
}

.privacy {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.875rem;
    opacity: 0.8;
}

@media screen and (max-width: 540px) {
    .row {
        flex-direction: column;
    }    
}