.container {
    display: flex;
    position: relative;
    flex-direction: column;
    min-width: 100%;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: var(--background-secondary-color);
    container-name: container;
    container-type: inline-size;
}