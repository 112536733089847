.container {
    --badge-color: var(--primary);
    display: flex;
    border: 2px solid var(--badge-color);
    border-radius: 8px;
    font-weight: 700;
    font-size: 1rem;
    color: var(--badge-color);
    width:fit-content;
    height: min-content;
    padding: 0 .25rem;
    text-transform: uppercase;
    white-space: nowrap;
}