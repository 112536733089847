.container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,30%);
    z-index: 999;
}

.card{
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #FFFFFF;
    padding: 1rem 1.5rem;
    border-radius: 12px;
    width: fit-content;
    max-height: 90dvh;
    overflow: hidden;
    gap: 1rem;
}

.header{
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;
}

.title{
    font-size: 1.50rem;
    color: rgba(var(--text-color-rgb), 88%);
}

.closeButton{
    padding: .3rem;
    border: 1px solid rgba(var(--text-color-rgb), 10%);
    aspect-ratio: 1/1;
    border-radius: 10px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.content{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 80vh;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.content::-webkit-scrollbar{
    display: none;
}

.content > .StripeElement {
    width: 100%;
}

.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    padding: 0 1.5rem;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid rgba(var(--text-color-rgb), 12%);
    background-color: var(--background-secondary-color);
    height: 88px;
}

@media screen and (max-width: 768px) {
    
    .card{
        width: 80%;
        padding: 1rem;
    }

    .content::-webkit-scrollbar{
        display: none;
    }

}

@media screen and (max-width: 540px) {
    
    .card{
        width: 90%;
        max-height: 90vh;
    }

}