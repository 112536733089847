.container {
    display: flex;
    width: 100%;
    position: relative;
}

.container.mobileFullScreen {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    border-radius: 0;
    background-color: black;
}

.fullscreen {
    width: 100%;
    margin: 0;
}

.player {
    display: flex;
    border-radius: 12px;
    overflow: hidden;
    max-width: 98vw;
    width: 100%;
    height: 100%;
}

.container.mobileFullScreen .player {
    max-width: 100vw;
    border-radius: 0px;
}

.bottomBar {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.6);
    opacity: 1;
    pointer-events: all;
    transition: .2s;
}

.bottomBar.hidden {
    opacity: 0;
    pointer-events: none;
    transition: 1s;
}

.controls {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: .5rem;
    align-items: center;
}

.rightControls {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.progressWrapper {
    position: relative;
    width: 100%;
    height: 8px;
}

.progress::-webkit-slider-thumb {
    appearance: none;
    width: 15px;
    height: 15px;
    opacity: 0;
    background: transparent;
    border-radius: 50%;
    cursor: pointer;
}

.progress {
    appearance: none;
    width: 100%;
    height: 8px;
    background: rgba(255, 255, 255, 0.3);
    outline: none;
    border-radius: 0px;
    overflow: hidden;
    margin: 0;
    position: relative;
    display: flex;
    cursor: pointer;
}


.progress::-webkit-slider-runnable-track {
    background: linear-gradient(to right, var(--primary) 0%, var(--primary) var(--progress), rgba(255, 255, 255, 0.2) var(--progress), rgba(255, 255, 255, 0.2) 100%);
    border-radius: 0;
}

.progress::-moz-range-thumb {
    width: 0px;
    height: 0px;
    background: transparent;
    border: none;
}

.progressMarker {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    height: 8px;
    background-color: white;
    /* Scegli il colore desiderato */
    transform: translateX(-50%);
    pointer-events: none;
    /* Per evitare che interferisca con il clic */
}

.controlButton {
    all: unset;
    cursor: pointer;
}

.controlIcon {
    height: 24px;
    width: 24px;
    max-width: 24px;
    max-height: 24px;
}

.bufferedRanges {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    /* Disabilita interazioni con il mouse */
    z-index: 1;
    /* Assicurati che stia sotto il cursore di avanzamento */
}

.bufferedSegment {
    position: absolute;
    height: 100%;
    background-color: rgba(200, 200, 200, 0.5);
    /* Colore del buffer, semi-trasparente */
    border-radius: 4px;
    /* Aggiunge un leggero arrotondamento */
    pointer-events: none;
    /* Evita che il buffer interferisca con l'interazione utente */
}

.playPauseOverlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    border-radius: 120px;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: .5s;
    pointer-events: none;
}

.playPauseOverlay.hidden {
    opacity: 0;
    transition: .5s;
}


.overlayIcon {
    width: 60px;
    height: 60px;
}

.resume {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    align-items: center;
    position: absolute;
    bottom: 5rem;
    right: .5rem;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    padding: .5rem;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.6);
    opacity: 1;
    pointer-events: all;
    transition: .2s;
}

.resume.hidden {
    opacity: 0;
    pointer-events: none;
    transition: .2s;
}

.resumeIcon {
    height: 22px;
    width: 22px;
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.buffering {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (hover: hover) {

    .resume:hover {
        border: 1px solid rgba(255, 255, 255, 0.7);
    }

}

@media screen and (max-width: 540px) {
    
    .playPauseOverlay {
        width: 60px;
        height: 60px;
    }

    .overlayIcon {
        width: 30px;
        height: 30px;
    }
}